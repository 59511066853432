<template lang="html">
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title>
        <h2 class="headline mb-0">{{ $tc('title.fornecedor', 1) }}</h2>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <metadados-container-layout
          v-if="metadadosFornecedor != null"
          :metadados-entidade="metadadosFornecedor"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="fornecedor"
          :formulario-filtros="false"
          :somenteLeitura="somenteLeitura"
          :novoCadastro="true"
          @MetadadosContainerLayout_abrirCadastro="abrirCadastro"
          @MetadadosContainerLayout_save="validarForm"
          @MetadadosContainerLayout_form_Invalido="validarForm"
          ref="container">
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-select
              id="tipo_pessoa" name="tipo_pessoa"
              :rules="[rules.required]"
              :items="tiposPessoa"
              v-model="tipoPessoa"
              item-text="text"
              item-value="value"
              @change="limparCampoDocumento"
              :label="`${$tc('label.tipo_pessoa', 1)} *`"
              :disabled="somenteLeitura">
            </v-select>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_codigo"
              name="campo_codigo"
              v-model="fornecedor.idExterno"
              :label="`${$tc('label.cod_fornecedor', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="10"
              maxlength="10"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_razao_social"
              name="campo_razao_social"
              v-model="fornecedor.desRazaoSocial"
              :label="`${$tc('label.razao_social', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4" slot="antes">
            <v-text-field
              id="campo_nome_fantasia"
              name="campo_nome_fantasia"
              v-model="fornecedor.desNomeFantasia"
              :label="`${isPessoaJuridica ? $tc('label.nome_fantasia', 1) + ' *' : $tc('label.nome_fantasia', 1)}`"
              :rules="[rules.pessoaJuridica]"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_cnpj"
              name="campo_cnpj"
              v-model="fornecedor.desCnpj"
              :label="`${isPessoaJuridica ? $tc('label.cnpj', 1) : $tc('label.cpf', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              :maxlength="`${isPessoaJuridica ? 18 : 14}`"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_ie"
              name="campo_ie"
              v-model="fornecedor.inscricaoEstadual"
              :label="`${isPessoaJuridica ? $tc('label.inscricao_estadual', 1) + ' *' : $tc('label.inscricao_estadual', 1)}`"
              :rules="[rules.pessoaJuridica]"
              :disabled="somenteLeitura"
              :counter="50"
              maxlength="50"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="nomBanco"
              name="nomBanco"
              v-model="fornecedor.nomBanco"
              :label="`${$tc('label.banco', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              type="text"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="agencia"
              name="agencia"
              v-model="fornecedor.vlrAgencia"
              :label="`${$tc('label.agencia', 1)} *`"
              :rules="[rules.required]"
              :disabled="somenteLeitura"
              type="text"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="conta_corrente"
              name="conta_corrente"
              v-model="fornecedor.vlrConta"
              :rules="[rules.required]"
              :label="`${$tc('label.conta', 1)} *`"
              :disabled="somenteLeitura"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_nome"
              name="campo_contato_nome"
              v-model="fornecedor.contato.nomContato"
              :label="`${$tc('label.contato', 1)}`"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_email"
              name="campo_contato_email"
              v-model="fornecedor.contato.vlrEmail"
              :label="`${$tc('label.email', 1)}`"
              :disabled="somenteLeitura"
              :counter="100"
              maxlength="100"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="antes">
            <v-text-field
              id="campo_contato_telefone"
              name="campo_contato_telefone"
              v-model="fornecedor.contato.vlrTelefone"
              :label="`${$tc('label.telefone', 1)}`"
              :disabled="somenteLeitura"
              return-masked-value
              v-mask="'(##) #####-####'"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4" slot="depois">
            <v-switch
              color="primary"
              :label="$t('label.ativar_inativa')"
              v-model="fornecedor.indAtivo"
              :disabled="somenteLeitura"
              class="mt-3">
            </v-switch>
          </v-col>
          <v-col cols="12" slot="depois">
            <endereco
              ref="endereco"
              @ENDERECO_valido="enderecoValido"
              @ENDERECO_invalido="enderecoInvalido"
              :somenteLeitura="somenteLeitura"
              :id="fornecedor.idEndereco"
              >
            </endereco>
          </v-col>
        </metadados-container-layout>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="canAccessCRUD" id="btn_cancelar_novo_fornecedor" @click.native="openCancelarDialog">{{ $t('label.cancelar') }}</v-btn>
        <v-btn v-else id="btn_voltar_fornecedor" @click.native="cancelar">{{ $t('label.voltar') }}</v-btn>
        <v-btn v-if="canAccessCRUD" id="btn_salvar_novo_fornecedor" @click.native="validarForm" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>

      <v-dialog v-model="dialogCadastroExtensao" persistent max-width="800px">
        <fornecedor-nova-extensao
          ref="fornecedorNovaExtensao"
          :somenteLeitura="somenteLeitura"
          :objeto="novaExtensao"
          @cancelar="fecharCadastroExtensao"
          @atualizarGrid="setarCadastroExtensao"
        />
      </v-dialog>
    </v-card>

    <confirm v-if="canAccessCRUD"
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', { text: this.$tc('title.fornecedor', 1).toLowerCase() })"
      @agree="salvar">
    </confirm>

    <confirm
      ref="cancelarDialog" :title="$t('title.atencao')"
      :message="$t('message.tem_certeza_cancelar')"
      @agree="cancelar">
    </confirm>
  </v-form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { skipLoading } from '../../../common/functions/loading';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import Fornecedor from '../Fornecedor';
import Endereco from '../../endereco/Endereco';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import FornecedorNovaExtensao from '../FornecedorNovaExtensao';

export default {
  name: 'FornecedorIndustriaForm',
  components: {
    MetadadosContainerLayout,
    FornecedorNovaExtensao,
    Endereco,
    Confirm,
  },
  data() {
    return {
      metadadoResource: this.$api.metadado(this.$resource),
      fornecedorResource: this.$api.fornecedor(this.$resource),
      tipoBeneficioResources: this.$api.tipoBeneficio(this.$resource),

      fornecedor: new Fornecedor(),
      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: false,
        md4: true,
        md6: false,
        md9: false,
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        pessoaJuridica: (value) => {
          if (!!this.tipoPessoa && this.tipoPessoa === 'JURIDICA') {
            if (value) {
              return !!value;
            }
            return this.$t('message.campo_obrigatorio_pessoa_juridica');
          }
          return !!value;
        },
        documento: (value) => {
          if (!!this.tipoPessoa && this.tipoPessoa === 'JURIDICA') {
            if (value.replace(/\D+/g, '').length !== 14) {
              return this.$t('message.cnpj_tamanho');
            }
            return !!value;
          }
          if (!!this.tipoPessoa && this.tipoPessoa === 'FISICA') {
            if (value.replace(/\D+/g, '').length !== 11) {
              return this.$t('message.cpf_tamanho');
            }
            return !!value;
          }
          return !!value;
        },
      },
      dialogCadastroExtensao: false,
      novaExtensao: {},
      tiposPagamento: [],
      tipoPessoa: {
        text: 'Jurídica',
        value: 'JURIDICA',
      },
      tiposPessoa: [
        {
          text: 'Física',
          value: 'FISICA',
        },
        {
          text: 'Jurídica',
          value: 'JURIDICA',
        },
      ],
      somenteLeitura: false,
    };
  },
  computed: {
    ...generateComputed('FORN', [
      'canAccessCRUD',
    ]),
    ...mapGetters('metadados', [
      'getFornecedorMetadado',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
      'indAmbienteProdutivo',
    ]),
    ...mapGetters('implantacao', [
      'indAmbienteProdutivo',
    ]),
    metadadosFornecedor() {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        return this.getFornecedorMetadado;
      }
      return undefined;
    },
    isPessoaJuridica() {
      return !!this.tipoPessoa
             && (this.tipoPessoa.value === 'JURIDICA' || this.tipoPessoa === 'JURIDICA');
    },
  },
  methods: {
    ...mapActions('metadados', [
      'setFornecedorMetadado',
    ]),
    carregarFornecedor() {
      const { id } = this.$route.params;
      this.somenteLeitura = this.$route.params.somenteLeitura;
      skipLoading();
      this.fornecedorResource.buscar({ id })
        .then((res) => {
          this.fornecedor = { ...this.fornecedor, ...res.body };
          this.tipoPessoa = this.fornecedor.tipoPessoa;
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enderecoValido(endereco) {
      this.fornecedor.endereco = endereco;
      this.openSaveDialog();
    },
    enderecoInvalido(endereco) {
      this.fornecedor.endereco = endereco;
    },
    limparCampoDocumento() {
      if (this.fornecedor.desCnpj) {
        this.fornecedor.desCnpj = null;
      }
    },
    validarForm() {
      this.$refs.endereco.isValido(this.$refs.form.validate());
    },
    openSaveDialog() {
      this.$refs.confirmDialog.open();
    },
    openCancelarDialog() {
      this.$refs.cancelarDialog.open();
    },
    salvar() {
      this.fornecedor.idsProdutos = [];
      this.fornecedor.listaprodutos.forEach((value) => {
        this.fornecedor.idsProdutos.push(value.id);
      });

      this.fornecedor.indModoVarejo = this.indModoVarejo;
      this.fornecedor.tipoPessoa = this.tipoPessoa.value || this.tipoPessoa;
      if (this.fornecedor.desNomeFantasia === null
          || this.fornecedor.desNomeFantasia === undefined) {
        this.fornecedor.desNomeFantasia = this.fornecedor.desRazaoSocial;
      }

      const objArmazenamento = {
        ...this.fornecedor,
        ...this.$refs.container.getValoresDependencias(),
      };

      this.exibeAlertaLgpd();
      if (this.fornecedor.id) {
        this.atualizar(objArmazenamento);
      } else {
        this.inserir(objArmazenamento);
      }
    },
    exibeAlertaLgpd() {
      if (this.indAmbienteProdutivo) return;

      const alertaLgpd = this.$t('message.lgpd');
      alert(alertaLgpd); // eslint-disable-line
    },
    inserir(obj) {
      this.exibeAlertaLgpd();
      this.fornecedorResource.gravar(obj)
        .then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    atualizar(obj) {
      this.exibeAlertaLgpd();
      const { id } = obj;
      this.fornecedorResource.atualizar({ id }, obj)
        .then(() => {
          this.$toast(this.$t('message.atualizado_com_sucesso'));
          this.$router.push({ name: this.$route.params.from || 'fornecedor' });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    cancelar() {
      this.$router.push({ name: this.$route.params.from || 'fornecedor' });
    },
    abrirCadastro(metadados) {
      this.novaExtensao = {};
      this.novaExtensao.indAtivo = true;
      this.novaExtensao.idNivelExtensao = metadados.idNivelExtensao;
      this.novaExtensao.metadados = metadados;
      this.$refs.fornecedorNovaExtensao.setNivel(metadados.idNivelExtensao);
      this.dialogCadastroExtensao = true;
    },
    fecharCadastroExtensao() {
      this.dialogCadastroExtensao = false;
    },
    setarCadastroExtensao(obj, metadados) {
      if (metadados.manyToMany) {
        if (!this.fornecedor[metadados.label]) {
          this.fornecedor[metadados.label] = [];
        }
        this.fornecedor[metadados.label].push(obj);
      } else {
        this.fornecedor[metadados.label] = obj;
      }
      this.$refs.container.refresh();
      this.dialogCadastroExtensao = false;
    },
    buscarTiposBeneficios() {
      this.tipoBeneficioResources.listarAtivos().then((response) => {
        this.tiposPagamento = response.data;
      });
    },
  },
  beforeMount() {
    this.buscarTiposBeneficios();
    if (this.$route.params.id) {
      if (this.getFornecedorMetadado && this.getFornecedorMetadado.mapaEntidades) {
        this.carregarFornecedor();
      } else {
        this.setFornecedorMetadado({
          resource: this.metadadoResource.listarFornecedor,
          params: null,
          callback: this.carregarFornecedor,
        });
      }
    }
  },
};
</script>
